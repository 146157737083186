<template>
    <div>
        <el-card class="box-card" v-loading="loadinga" v-if="realname === '未实名'">
            <div slot="header" class="clearfix">
                <h1 class="h1">实名认证</h1>
                <span class="text item">一旦实名成功则无法更改实名。认证接口对接阿里云二要素实名API。我们允许未成年实名，但请不要使用非本人身份证实名。</span>
            </div>
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="姓名" prop="name">
                    <el-input type="text" v-model="ruleForm.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="idcard">
                    <el-input v-model="ruleForm.idcard"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :loading="loading" @click="submitForm('ruleForm')">提交</el-button>
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <el-card v-if="(realname === '已实名')">
            <h2>
                <i class="el-icon-ice-tea" style="color: #409EFF; font-size: 40px;"></i>
                <el-divider direction="vertical"></el-divider>
                您已经完成了实名！
            </h2>
        </el-card>
    </div>
</template>
  
<script>
import { getToken, setToken, removeToken } from "@/utils/setToken.js";
import axios from 'axios'

export default {
    data() {
        const token = getToken("token");
        const userid = getToken("userid");
        this.realname = getToken("realname");
        return {
            loading: false,
            loadinga: false,
            ruleForm: {
                name: '', // 用户姓名
                idcard: '', // 身份证号
            },
            rules: {
                name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                idcard: [
                    { required: true, message: '请输入身份证号', trigger: 'blur' },
                    { pattern: /^\d{17}[\dXx]$/, message: '请输入有效的身份证号', trigger: 'blur' },
                ],
            },
            usertoken: token,
            userid: userid,
        };
    },
    created() {
        const usertoken = getToken('token');

        if (usertoken) {
            this.setData();
        } else {
            removeToken('token');
            this.$router.push('/login');
            this.$message({
                message: "无效的token，请重新登录",
                type: "error",
            });
        }
    },
    methods: {
        setData(params) {
            this.loadinga = true;
            const usertoken = getToken('token');
            axios
                .get('https://cf-v1.uapis.cn/api/userinfo.php', {
                    params: { usertoken, ...params },
                })
                .then(response => {
                    if (response.data.error) {
                        removeToken('token');
                        this.$router.push('/login');
                        this.$message({
                            message: "无效的token，请重新登录",
                            type: "error",
                        });
                    } else {
                        const { userid, username, email, qq, usergroup, bandwidth, tunnel, realname, integral, userimg, term, tunnelstate } = response.data;

                        setToken('userid', userid);
                        setToken('username', username);
                        setToken('email', email);
                        setToken('qq', qq);
                        setToken('usergroup', usergroup);
                        setToken('bandwidth', bandwidth);
                        setToken('tunnel', tunnel);
                        setToken('realname', realname);
                        setToken('integral', integral);
                        setToken('userimg', userimg);
                        setToken('term', term);
                        setToken('tunnelstate', tunnelstate);
                    }
                })
                .catch(error => {
                    console.error('请求API时发生错误:', error);
                })
                .finally(() => {
                    this.loadinga = false;
                });
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    // 表单验证通过，调用实名认证API
                    const formData = new FormData();
                    formData.append('name', this.ruleForm.name);
                    formData.append('idcard', this.ruleForm.idcard);
                    formData.append('usertoken', this.usertoken); // 将usertoken添加到formData
                    formData.append('userid', this.userid);

                    fetch('https://cf-v1.uapis.cn/api/realname.php', {
                        method: 'POST',
                        body: formData,
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.status === 'success') {
                                this.$message.success('实名认证成功');
                            } else if (data.status === 'error') {
                                if (data.message === '请不要重复实名') {
                                    this.$message.warning('您已经实名认证过了');
                                } else {
                                    this.$message.error(data.message);
                                }
                            }
                        })
                        .catch((error) => {
                            console.error('API请求错误:', error);
                            this.$message.error('实名认证失败，请稍后再试');
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    console.log('表单验证失败');
                    return false;
                }
            })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
    },
};
</script>
<style>
.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
    color: red;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}

.h1 {
    color: #409EFF;
}
</style>